/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';
import WrapperContent from '@components/Layout/WrapperContent';
import NewsTimeLine, { PropsAutor, PropsMetaInfo, PropsTitle } from '@/components/Layout/NewsTimeLine';
import { TimeLine } from '@/types/types';
import Titulo from '@/components/Layout/Title';
import { mglBlue, mglGray } from '@/styles/designSystemColors';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

SwiperCore.use([Navigation, Pagination, Autoplay]);

export interface featuredProducts {
  featuredProductsAnalysis: TimeLine[];
  bgColor?: string;
  isOnHeader?: boolean;
  paddingOnBottom?: boolean;
  paddingOnTop?: boolean;
  isSubDomain?: boolean;
}

export default function FeaturedProductsAnalysis({
  featuredProductsAnalysis,
  bgColor,
  isOnHeader = false,
  paddingOnBottom = true,
  paddingOnTop = false,
  isSubDomain = false,
}: featuredProducts) {
  const MAX_PRODUCTS_TO_SHOW = 12;

  const pagination = {
    clickable: false,
  };

  const PropMetaInfo: PropsMetaInfo = {
    age: false,
    category: false,
  };

  const PropTitle: PropsTitle = {
    altColorInHover: true,
    colorHover: mglBlue.mglBlue400,
  };

  const PropAutor: PropsAutor = {
    image: true,
    imgInImage: true,
    imgRounded: true,
    text: false,
  };

  const navigation = {
    nextEl: '#analises-swiper-button__next',
    prevEl: '#analises-swiper-button__prev',
  };

  return (
    <>
      <WrapperContent backgroundColor={bgColor || mglGray.mglGray200} isOnHeader={isOnHeader} paddingOnBottom={paddingOnBottom} paddingOnTop={paddingOnTop}>
        <Titulo texto="Análises" urlRedirect={isSubDomain ? `${publicRuntimeConfig.NEXT_PUBLIC_URL}/analises/` : '/analises/'} color="black" showChip alignRight />
        <div className="relative [&_.container-swiper\_\_next]:-right-2 [&_.container-swiper\_\_prev]:-left-2 [&_.container-swiper]:z-[5] [&_.container-swiper]:opacity-100 [&_.container-swiper]:transition-opacity [&_.container-swiper]:duration-300 [&_.container-swiper]:ease-ease [&_.swiper-button]:bottom-[14%] [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:h-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:w-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:rounded-2xl [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:bg-black/20 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s] [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:w-[26px] [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:bg-mglBlue400 [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s] [&_swiper-wrapper]:m-0 [&_swiper-wrapper]:mb-6">
          <Swiper
            slidesPerView={6}
            spaceBetween={14}
            slidesPerGroup={6}
            speed={1000}
            pagination={pagination}
            navigation={navigation}
            breakpoints={{
              240: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 16,
              },
              1660: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 16,
              },
            }}>
            {featuredProductsAnalysis.slice(0, MAX_PRODUCTS_TO_SHOW).map(product => (
              <SwiperSlide key={product.url}>
                <NewsTimeLine
                  key={product.url}
                  news={product}
                  title
                  propsTitle={PropTitle}
                  effectImage
                  metaInfo
                  propsMetaInfo={PropMetaInfo}
                  autor
                  propsAutor={PropAutor}
                  isSubDomain={isSubDomain}
                  headingTag="h3"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="container-swiper container-swiper__prev">
            <div id="analises-swiper-button__prev" className="swiper-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1.5em"
                height="1.5em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                style={{
                  verticalAlign: '-0.125em',
                  transform: 'rotate(180deg)',
                }}>
                <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
              </svg>
            </div>
          </div>
          <div className="container-swiper container-swiper__next">
            <div id="analises-swiper-button__next" className="swiper-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1.5em"
                height="1.5em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                style={{
                  verticalAlign: '-0.125em',
                  transform: 'rotate(360deg)',
                }}>
                <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
              </svg>
            </div>
          </div>
        </div>
      </WrapperContent>
    </>
  );
}

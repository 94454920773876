import Titulo from '@/components/Layout/Title';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';
import WrapperContent from '@/components/Layout/WrapperContent';
import getValue from '@/utils/getValue';
import { FeaturedDeal, OfferPrice } from '@/shared/types/home.types';
import Link from 'next/link';
import Chip from '@/components/Layout/Chip';
import currencyParser from '@/utils/currencyParser';
import useBreakpoint from '@/hooks/useBreakpoint';
import * as S from '../../Layout/DealsBox/styles';
import { mglGray } from '@/styles/designSystemColors';
import ImageComponent from '@/components/Layout/Image';
import Icon from '@/components/Layout/Icon';

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface FeaturedDealsProps {
  deals: FeaturedDeal[];
  indexComponent?: string;
}

export default function FeaturedDeals({ indexComponent = '', deals = [] }: FeaturedDealsProps) {
  const pagination = {
    clickable: true,
  };

  const navigation = {
    nextEl: `#ofertas-swiper-button__next${indexComponent}`,
    prevEl: `#ofertas-swiper-button__prev${indexComponent}`,
  };

  const [isMobile] = useBreakpoint('phone');

  const generatePriceText = (price: OfferPrice) => {
    switch (price.tipo) {
      case 0:
        return <S.DealsValue>Grátis</S.DealsValue>;
      case 1:
        return (
          <>
            <S.DealsSecondaryValue>A partir de</S.DealsSecondaryValue>
            <S.DealsValue>
              R$ {getValue(price.valor).split(',')[0]},<S.DealsCents>{`${getValue(price.valor).split(',')[1]}`}</S.DealsCents>
            </S.DealsValue>
          </>
        );
      case 2:
        return (
          <>
            <S.DealsSecondaryValue discount>{currencyParser(price.valorOriginal)}</S.DealsSecondaryValue>
            <S.DealsValue>
              R$ {getValue(price.valor).split(',')[0]},<S.DealsCents>{`${getValue(price.valor).split(',')[1]}`}</S.DealsCents>
            </S.DealsValue>
          </>
        );
      case 3:
        return (
          <>
            <S.DealsValue>
              R$ {getValue(price.valor).split(',')[0]},<S.DealsCents>{`${getValue(price.valor).split(',')[1]}`}</S.DealsCents>
            </S.DealsValue>
            <S.DealsSecondaryValue>Em {price.parcelas}x sem juros</S.DealsSecondaryValue>
          </>
        );
      case 4:
        return (
          <>
            <S.DealsSecondaryValue>Em {price.parcelas}x de</S.DealsSecondaryValue>
            <S.DealsValue>
              R$ {getValue(price.valor).split(',')[0]},<S.DealsCents>{`${getValue(price.valor).split(',')[1]}`}</S.DealsCents>
            </S.DealsValue>
          </>
        );
      default:
        return <></>;
    }
  };

  const getTopOfferURL = (url: string) => {
    try {
      const topOfferURL = new URL(url);
      topOfferURL.searchParams.set('utm_source', 'top10home');
      return topOfferURL.toString();
    } catch (err) {
      if (err instanceof TypeError) return '';
    }
    return '';
  };

  return (
    <WrapperContent backgroundColor={mglGray.mglGray200} isOnHeader paddingOnBottom>
      <Titulo texto="🛒 Top 10 no CT Ofertas" color="black" />
      <div className="relative mt-4 [&_.container-swiper\_\_next]:-right-2 [&_.container-swiper\_\_prev]:-left-2 [&_.container-swiper]:z-[5] [&_.container-swiper]:opacity-100 [&_.container-swiper]:transition-opacity [&_.container-swiper]:duration-300 [&_.container-swiper]:ease-in-out [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:h-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:w-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:rounded-2xl [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:bg-black/20 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s] [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:w-[26px] [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:bg-mglBlue400 [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s]">
        <Swiper
          centeredSlidesBounds
          speed={1000}
          pagination={pagination}
          navigation={navigation}
          breakpoints={{
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 16,
            },
            1280: {
              slidesPerView: 2.5,
              slidesPerGroup: 3,
              spaceBetween: 16,
            },
            1660: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 16,
            },
          }}>
          {deals.map(deal => (
            <SwiperSlide key={getTopOfferURL(deal.url)} className="relative h-[180px]">
              <Link href={getTopOfferURL(deal.url)} passHref>
                <S.DealsUnitContainer href={getTopOfferURL(deal.url)} target="_blank">
                  <S.DealsProductImageContainer className="self-baseline">
                    <ImageComponent src={deal.imagens[300]} blurhash={deal.blurhash} alt={deal.titulo} height={76} width={76} />
                  </S.DealsProductImageContainer>

                  <S.DealsRow>
                    <S.DealsColumn fullWidth>
                      <S.DealsPartnerContainer>
                        <span className="text-xs text-mglGray900">
                          {deal.loja && deal.loja.nome && 'Vendido por '}
                          <strong>{deal?.loja?.nome || 'Aproveite!'}</strong>
                        </span>
                      </S.DealsPartnerContainer>
                      <S.DealsProductDescription inSwiper>{deal.titulo}</S.DealsProductDescription>
                      <S.DealsRow isProduct>
                        <S.DealsValueContainer>{generatePriceText(deal.preco)}</S.DealsValueContainer>
                        <Chip
                          texto="ir à loja"
                          color="white"
                          backgroundColor="green"
                          borderColor="green"
                          hover="green"
                          className="chip-button"
                          chipSize="medium"
                          posIcon={!isMobile && <Icon icon="mdi:open-in-new" fontSize="0.75rem" />}
                        />
                      </S.DealsRow>
                    </S.DealsColumn>
                  </S.DealsRow>
                </S.DealsUnitContainer>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="container-swiper container-swiper__prev">
          <div id={`ofertas-swiper-button__prev${indexComponent}`} className="swiper-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              width="1.5em"
              height="1.5em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
              style={{
                verticalAlign: '-0.125em',
                transform: 'rotate(180deg)',
              }}>
              <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
            </svg>
          </div>
        </div>
        <div className="container-swiper container-swiper__next">
          <div id={`ofertas-swiper-button__next${indexComponent}`} className="swiper-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              width="1.5em"
              height="1.5em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
              style={{
                verticalAlign: '-0.125em',
                transform: 'rotate(360deg)',
              }}>
              <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
            </svg>
          </div>
        </div>
      </div>
    </WrapperContent>
  );
}
